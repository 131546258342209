#logoSvg {
  cursor: pointer;
}
svg:hover #tagOpen {
  transition: transform 0.3s ease; /* Adding a smooth transition effect */
  transform: translateX(-10px); /* Applying the translation on hover */
}

#tagOpen {
  transition: transform 0.3s ease; /* Adding a smooth transition effect */
  &:hover {
    transform: translateX(-10px); /* Applying the translation on hover */
  }
}

svg:hover #Title {
  fill: #ffffff; /* Initial fill color */
  transition: all 0.3s ease; /* Adding a smooth transition effect */
  transform: translateX(-4px); /* Applying the translation on hover */
}

#Title {
  fill: #89fdea; /* Initial fill color */
  transition: all 0.3s ease; /* Adding a smooth transition effect */
  &:hover {
    transform: translateX(-4px); /* Applying the translation on hover */
  }
}

svg:hover #tagClose {
  transition: transform 0.3s ease; /* Adding a smooth transition effect */
  transform: translateX(5px); /* Applying the translation on hover */
}

#tagClose {
  transition: transform 0.3s ease; /* Adding a smooth transition effect */
  &:hover {
    transform: translateX(5px); /* Applying the translation on hover */
  }
}

svg:hover #box {
  fill: azure;
  transform-origin: center; /* Setting the transform origin to the center */
  transition: width 0.3s ease, transform 0.3s ease; /* Adding smooth transitions */
  transform: scaleX(1.1); /* Expanding width from the center */
  //   width: 120px; /* New width on hover */
}

#box {
  fill: azure;
  transform-origin: center; /* Setting the transform origin to the center */
  transition: width 0.3s ease, transform 0.3s ease; /* Adding smooth transitions */
  &:hover {
    // width: 120px; /* New width on hover */
    transform: scaleX(1.1); /* Expanding width from the center */
  }
}
